html {
  height: 100vh;
  background-color: #fbfbfb;
}
:root {
  --app-height: 100%;
}
body {
  margin: 0;
  padding: 0;
  /* height: 100vh; */
  width: 100%;
  overflow-y: scroll;
}
/* * {
  font-family: 'Noto Sans KR', 'Kosugi Maru', 'sans-serif';
} */

::-webkit-scrollbar {
  position: absolute;
  width: 0px;
}

.layout {
  /* width: 100%;
  height: 100vh; */
  /* overflow-y: scroll; */
  /* overflow: overlay; */
}

textarea:focus,
input:focus {
  outline: none;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  /* padding-left: 16px !important; */
}

.css-1nvnyqx-MuiPaper-root-MuiDrawer-paper {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.css-1nvnyqx-MuiPaper-root-MuiDrawer-paper {
  background-color: #ffffffd8 !important;
}
/* =[Loading]=============================================================================================== */
.gooey {
  top: 50%;
  left: 50%;
  width: 142px;
  height: 40px;
  margin: -20px 0 0 -71px;
  background-color: rgba(229, 229, 229, 0.895);
  filter: contrast(20);
}

.dot {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 12px;
  left: 15px;
  filter: blur(4px);
  background-color: #000;
  border-radius: 50%;
  transform: translateX(0);
  animation: dot 2.8s infinite;
}
.dots {
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  animation: dots 2.8s infinite;
}
.dots span {
  display: block;
  float: left;
  width: 12px;
  height: 12px;
  margin-left: 12px;
  filter: blur(4px);
  background: #000;
  border-radius: 50%;
}

@keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}

.invalid_page_container {
  text-align: center;
  padding-top: 40vh;
  min-height: 600px;
}
.default-background {
  position: absolute;
  z-index: 100;
  /* background: linear-gradient(175.2deg, #669ff4 0%, #b46feb 100%); */
  /* box-shadow: 0px 0px 37px -16px #000000; */
}

.center-all2 {
  display: flex;
  align-items: center;
  align-content: center;
}
.row {
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
}

.row.items > div {
  margin-right: 10px;
}

.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute !important;
}

.absolute-vertical-center {
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute !important;
}

.small-button {
  padding: 5px;
  width: 30px;
  text-align: center;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  margin-right: 10px;
  line-height: 2;
  cursor: pointer;
}

.card-canvas {
  width: 100%;
  /* padding-bottom: 100%; */
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  margin-right: 20px;
}

.card-canvas.editor {
  max-width: 400px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin: auto;
  border: 1px solid #8e8e8e;
  aspect-ratio: 1 / 1;
}

.card-canvas-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.card-canvas-text-contents {
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
  padding: 5px;
  border-radius: 5px;
}

.card-canvas-text-contents-preview {
  text-align: center;
  vertical-align: middle;
  border: 1px dotted #c8c8c8;
  word-break: break-all;
  padding: 5px;
  border-radius: 5px;
}
.card-canvas-title-container {
  position: absolute;
  bottom: 0px;
  top: 30px;
  left: 0;
  right: 0;
  padding: 10px;
  width: auto;
}

.card-canvas-title {
  font-weight: 500;
  background: #1f439e;
  width: auto;
  color: #fff;
  display: inline-block;
  font-size: 16 px;
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
}

.card-canvas-title-link-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-canvas-title-link {
  width: 50px;
  height: 40px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: orange;
  bottom: 0;
  margin: auto;
}

[contenteditable='true']:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  color: rgb(24, 19, 19);
  display: block;
}

.color-boxes > div {
  width: 30px;
  height: 30px;
  margin: 5px;
  margin-top: 10px;
  box-shadow: 0px 0px 7px -1px #c8c8c8;
  border-radius: 30px;
  cursor: pointer;
}

.round-button {
  width: 30px;
  height: 30px;
  margin: 5px;
  margin-top: 10px;
  box-shadow: 0px 0px 7px -1px #c8c8c8;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #fff;
  z-index: 100;
}

.slider {
  scroll-snap-type: x mandatory;
  display: flex;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
}
.card-section {
  scroll-snap-align: start;
  text-align: center;
  position: relative;
}

.social-icons > img {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.input {
  border-radius: 8px;
  height: 40px;
  width: 100%;
  text-align: 'center';
  padding: 0 10px 0 10px;
}

.editor-input-container {
  border-radius: 5px;
  height: 60px;
  text-align: center;
}

.editor-input {
  width: 90%;
  height: 50px;
  padding: 5px;
  margin-right: 20px;
  border: 0;
  font-size: 20px;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  font-size: 20px;
  border-radius: 8px;
  border: none;
}
.button:disabled {
  background: #efefef !important;
  color: rgb(182, 182, 182) !important;
}

.button.default {
  color: #fff;
}

.button.invalid {
  background: #efefef;
  color: #fff;
}

.rounded-box {
  border-radius: 8px;
  border: 1px solid #dddddd;

  height: 50px;
  padding-left: 10px;
  display: table;
  width: calc(100% - 40px);
  cursor: pointer;
}

.rounded-box:hover {
  background: #efefef;
  color: '#000';
}

.reactEasyCrop_Container {
  background-color: #000;
  border: none;
}

.crop-container {
  position: relative;
  /* width: auto; */
  height: 300px;
}
.crop-inner {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.type-unselected {
  border: 2px solid transparent;
  padding: 5px;
}
.type-selected {
  border-radius: 10px;
  padding: 5px;
  margin: auto;
}

.link-container-text {
  border-radius: 10px;
  text-align: center;
  word-wrap: break-word;
  min-height: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  /* justify-content: space-between;
  
  position: relative;
  box-shadow: 0px 0px 7px -1px #c8c8c8; */
}

.link-container-video {
  border-radius: 10px;
  text-align: center;
  word-wrap: break-word;
  min-height: 50px;
  justify-content: space-between;
  display: flex;
  position: relative;
  box-shadow: 0px 0px 7px -1px #c8c8c8;
  overflow: hidden;
}

/* new */
.card-container {
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 15px;
}

.card-container.small {
  height: 60px;
  background: #fff;
}

.card-container.small {
  height: 60px;
}

.center-all {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.youtube-frame {
  width: 100%;
  border: 0;
  position: relative;
}

.simple-a-link {
  text-decoration: none;
  color: #000;
}
/* Simulator container child */
.admin-slider-child {
  scroll-snap-align: start;
  text-align: center;
  position: relative;
  min-width: 100%;
  height: calc(100vh - 80px);
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 150px;
  text-align: center;
  font-size: 18px;
  border-radius: 15px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}

.link-title {
  font-size: 18px;
}

.div-round {
  overflow: hidden;
  position: relative;
  z-index: 10;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.div-round::before {
  display: block;
  content: '';
}

.iframe-round {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  border: 0;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

/* Common here : 여기서부터 새로운 버전 */

.input {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 5px;
  vertical-align: middle;
  word-break: break-all;
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  border: none;
}

/* bottom sheet  */
[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 300 !important;
}
