/* kosugi-maru-regular - latin_japanese */
@font-face {
  font-family: 'Kosugi Maru';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/japanese/kosugi-maru-v14-latin_japanese-regular.ttf');
}

@font-face {
  font-family: 'Noto Sans';
  src: url('fonts/english/NotoSans-Regular.ttf');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}

:lang(jp) {
  font-family: 'Noto Sans', 'Kosugi Maru', sans-serif;
}

:lang(ko) {
  font-family: 'Noto Sans KR', sans-serif;
}

/* ===[Font]================================================================= */
.font12 {
  font-size: 12px;
}
.font14 {
  font-size: 14px;
}
.font16 {
  font-size: 16px;
}
.font24 {
  font-size: 24px;
}
.font32 {
  font-size: 32px;
}

.bold {
  font-weight: bold;
}
.bolder {
  font-weight: bolder;
}
.skiny {
  font-weight: 100;
}

.underline {
  text-decoration: 'underline';
}

/* ===[Color]================================================================= */

.blue {
  color: #3e64ea;
}

.gray {
  color: rgba(0, 0, 0, 0.5);
}

.light_gray {
  color: rgb(187, 187, 187);
}

.white {
  color: white;
}
.black {
  color: black;
}

.black_back {
  background-color: black;
}

.white_back {
  background-color: white;
}

/* ===[position]================================================================= */

/* .full_width {
  position: absolute;
  width: 100vw;
  left: calc(-50vw + 50%);
} */
.overlap_img {
}

.height100 {
  height: 100%;
}

.items_center {
  align-items: center;
}

.center {
  text-align: center;
}

.left {
  float: left;
}
.right {
  float: right;
  /* display: inline-block; */
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.full_screen {
  z-index: 101;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.full_screen::before {
  content: '';
  /* opacity: 0.85; */
  box-shadow: rgb(0, 0, 0) 0 0 0 9999px;
  z-index: 1000;
}

.padding_bottom_4 {
  padding-bottom: 4px;
}
.padding_bottom_8 {
  padding-bottom: 8px;
}
.padding_bottom_12 {
  padding-bottom: 12px;
}
.padding_bottom_16 {
  padding-bottom: 16px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding_bottom_24 {
  padding-bottom: 24px;
}
.padding_bottom_28 {
  padding-bottom: 28px;
}
.padding_bottom_32 {
  padding-bottom: 32px;
}
.padding_top_4 {
  padding-top: 4px;
}

.padding-top-20 {
  padding-top: 20px;
}
.padding-top-40 {
  padding-top: 40px;
}
.padding_top_80 {
  padding-top: 80px;
}

/* ===[]================================================================= */

.height_24 {
  height: 24px;
}
.height_32 {
  height: 32px;
}
.height_40 {
  height: 40px;
}
.height_48 {
  height: 48px;
}
.height_62 {
  height: 62px;
}

/* ===[]================================================================= */
.btn {
  cursor: pointer;
}
.btn:hover {
  opacity: 0.5;
}

.btn_up {
  cursor: pointer;
  top: 0;
  transition: all 0.3s ease-in-out;
}

.btn_up:hover {
  top: -5px;
  position: relative;
}

/* do not remove */
.global-width {
  max-width: 550px;
  padding: 0 20px;
  margin: auto;
}
